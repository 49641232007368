.panel__head {
  cursor: pointer;
  appearance: none;
  background-color: #0000;
  border: 0;
  padding: 0;
}

.panel__body {
  -webkit-overflow-scrolling: touch;
  transition: height .3s ease-in-out;
  overflow: hidden;
}

.panel__content {
  opacity: 0;
  transition: opacity .3s ease-in-out;
}

.panel[aria-expanded="true"] .panel__content {
  opacity: 1;
}
/*# sourceMappingURL=demo.27d64f40.css.map */
